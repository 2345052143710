<template>
  <div>
    <DatePicker
      mode="date"
      v-model="selectedDateLocal"
      @input="dateUpdated"
      :timezone="timezone"
      :masks="masks"
      weekday="2"
    >
      <template #default="{ inputValue, inputEvents }">
        <input
          class="px-3 py-1 border rounded"
          :value="inputValue"
          v-on="inputEvents"
        />
      </template>
    </DatePicker>
  </div>
</template>
<script>
// You need a specific loader for CSS files
// import "vue-datetime/dist/vue-datetime.css";
// import { Datetime } from "vue-datetime";
// import { Settings } from "luxon";

import { DatePicker } from "v-calendar";
import moment from "moment";

// Settings.defaultLocale = "en";
export default {
  components: {
    // Datetime,
    // Calendar,
    DatePicker,
  },
  props: {
    selectedDate: {
      type: String,
      default: "",
    },
    dialogType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedDateLocal: "",
      timezone: "",
      masks: {
        input: "MM/DD/YYYY",
      },
    };
  },
  watch: {
    selectedDate: function (newVal) {
      this.selectedDateLocal = newVal;
    },
    deep: true,
    immediate: true,
  },
  created() {
    // new logic: if date given then no need to set timezone
    if (this.selectedDate) {
      this.selectedDateLocal = this.selectedDate;
    } else {
      this.timezone = this.getTimeZone();
      this.selectedDateLocal = this.getCurrentDateTime(); // current date
    }

    // old logic
    // if (this.dialogType != "create") {
    //  this.timezone = this.getTimeZone();
    // }
  },
  methods: {
    dateUpdated() {
      if (this.selectedDateLocal) {
        this.selectedDateLocal = moment(this.selectedDateLocal).format(
          "YYYY-MM-DD"
        );
        this.$emit("dateUpdated", this.selectedDateLocal);
      } else {
        this.$emit("dateUpdated", "");
      }
    },
  },
};
</script>
